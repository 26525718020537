/** by.Odin
 * spacBook 공통타입 정의
 * [warning] 해당 Method명 앞 대문자, 사용시 주의!
 */

let type = {};

type.CategoryOpts = [
  // { value: '', text: '전체' },
  { value: 'expendable', text: '소모품' },
  { value: 'windows', text: '창호' },
  { value: 'material', text: '건자재' },
  { value: 'etc', text: '기타' },
]
type.OriginPlaceOpts = [
  { value: 'korea', text: '한국' },
  { value: 'USA', text: '미국' },
  { value: 'japan', text: '일본' },
  { value: 'china', text: '중국' },
  { value: 'germany', text: '독일' },
  { value: 'russia', text: '러시아' },
]

module.exports = type;